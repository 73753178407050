<template>
  <div class="hero-section">
    <!-- :style="{
      'background-image':
        'url(' + require('../assets/imgs/herosection.png') + ')',
    }" -->
    <!-- <img class="sub-image-1" src="../assets/imgs/yellow_left.png" alt="" />
    <img class="sub-image-2" src="../assets/imgs/yellow_right.png" alt="" /> -->
    <b-container>
      <b-row>
        <b-col
          cols="12"
          md="8"
          class="d-flex flex-column position-relative centered hero-section__main-col"
        >
          <p class="hero-section__title">
            <!-- Geld besparen èn geld <br />
            verdienen -->
            <span class="hero-section__white">
              <!-- met <br />
              zonnepanelen? -->
              <!-- Prijs zonnepanelen <br />
              extreem laag! -->
              ZONNEPANELEN HEBBEN MEER VOORDELEN DAN OOIT
            </span>
          </p>
          <p class="my-md-3 my-2 hero-section__secondaryTitle">
            Tot 40% premie op zonnepanelen + de laagste prijs ooit!
          </p>
          <p class="my-md-3 my-2 hero-section__text">
            Met onze unieke dakscan ontdek jij:
          </p>

          <div class="d-flex align-items-center mt-1 mt-md-2">
            <img
              src="@/assets/imgs/checkmark.png"
              class="check-img"
              alt="My Image"
            />
            <span class="hero-section__text ml-1 ml-md-3"
              >Of jouw dak geschikt is</span
            >
          </div>
          <div class="d-flex align-items-center mt-1 mt-md-2">
            <img
              src="@/assets/imgs/checkmark.png"
              class="check-img"
              alt="My Image"
            />
            <span class="hero-section__text ml-1 ml-md-3"
              >Een schatting van hoeveel jij kunt verdienen</span
            >
          </div>
          <div class="d-flex align-items-center mt-1 mt-md-2">
            <img
              src="@/assets/imgs/checkmark.png"
              class="check-img"
              alt="My Image"
            />
            <span class="hero-section__text ml-1 ml-md-3"
              >Welke installateur beschikbaar zijn</span
            >
          </div>
          <div class="d-flex align-items-center mt-2 mt-md-2">
            <!-- <img
              src="@/assets/imgs/checkmark.png"
              class="check-img"
              alt="My Image"
            /> -->
            <span class="hero-section__text d-none d-md-flex"
              >Ontvang gratis tenminste 4 superscherpe offertes + advies op
              maat!
            </span>
            <span class="hero-section__text my-2 d-md-none d-flex"
              >Ontvang gratis tenminste 4 superscherpe offertes <br />
              + advies op maat!
            </span>
          </div>
          <div class="d-flex align-items-center mt-1 mt-md-2">
            <span class="hero-section__text my-1"
              >Vergelijk en bespaar tot wel 40% extra op de aanschafprijs.
            </span>
          </div>

          <div class="d-flex">
            <b-button
              class="hero-section__btn position-relative"
              @click="$emit('nextPage')"
            >
              Start Gratis Dakscan ‌
              <p class="hero-section__sub-text position-absolute">
                Klaar binnen 1 minuut
              </p>
            </b-button>
          </div>

          <img
            src="@/assets/imgs/arrow.png"
            class="position-absolute arrow"
            alt="My Image"
          />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
// import backgroundImage from "../assets/imgs/herosection.png";

export default {
  name: "HeroSection",
};
</script>

<style lang="scss" scoped>
.centered {
  @media (min-width: 1198.99px) and (max-width: 3499.99px) {
    position: absolute;
    top: 50%;
    left: 41%;
    //bottom: 105px;
    transform: translate(-50%, 15%);
  }
  @media (max-width: 3499.99px) and (min-width: 1597.99px) {
    position: absolute;
    top: 50%;
    left: 41%;
    bottom: 350px;
    transform: translate(-50%, -60%);
  }
  @media (min-width: 627px) and (max-width: 1198.99px) {
    top: 50%;
    left: 41%;
    bottom: 130px;
    transform: translate(-50%, 30%);
  }
}
.sub-image-2 {
  height: 15vw;
  position: absolute;
  right: 0;
  @media (max-width: 625.99px) {
    display: none;
  }
}
.sub-image-1 {
  height: 70vh;
  @media (max-width: 625.99px) {
    display: none;
  }
}
.hero-section {
  // min-height: 100px !important;
  //background-size: 100% 100% !important;
  // background-size: cover;
  // background-position-x: right;
  background-image: url("../assets/imgs/headersolar.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: right;
  height: 95vh;
  // position: relative;
  // padding-top: 70px;
  // padding-bottom: 70px;

  overflow: hidden;
  @media (max-width: 3499.99px) and (min-width: 1198.99px) {
    height: 100vh;
  }
  @media (max-width: 3499.99px) and (min-width: 1598.99px) {
    height: 70vh;
  }
  @media (max-width: 625.99px) {
    background-image: url("../assets/imgs/header_image.png");
    height: 100dvh;
  }
  &__title {
    // font-size: 64px;
    font-size: 45px;
    // line-height: 58px;
    line-height: 44px;
    text-transform: uppercase;
    color: #ffcc01;
    font-weight: 800;
  }
  &__white {
    color: #ffffff !important;
  }
  &__secondaryTitle {
    font-size: 25px;
    color: #ffffff;
    font-weight: 700;
  }

  &__text {
    // font-size: 22px;
    // line-height: 36px;
    font-size: 16px;

    color: #ffffff;
    font-weight: 700;
  }

  &__sub-text {
    // font-size: 17px;
    font-size: 14px;
    letter-spacing: 0px;
    text-transform: none;
    color: #ffffff;
    font-weight: 400;
    right: 20px;
    top: 60px;
  }

  &__btn {
    // font-size: 17px;
    font-size: 14px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #1d1729;
    font-weight: 900;
    background: #ffcc01;
    margin-top: 20px;
    border-radius: 30px;
    padding: 15px 30px;
    border: none !important;
    &:hover,
    &:active {
      background-color: #ffcc01;
      color: #1d1729;
    }
  }

  .arrow {
    left: -80px !important;
    bottom: 5%;
  }

  &__main-col {
    margin-left: 10% !important;
  }

  @media (max-width: 1199.99px) {
    &__title {
      font-size: 40px;
    }
    &__secondaryTitle {
      font-size: 23px;
    }
  }
  @media (max-width: 991.99px) {
    &__main-col {
      margin-left: 5% !important;
      padding-top: 60px;
    }
    &__title {
      font-size: 35px;
    }
    &__secondaryTitle {
      font-size: 16px;
    }
    &__text {
      font-size: 14px;
    }
    &__sub-text {
      font-size: 12px;
      top: 50px;
    }
    &__btn {
      font-size: 12px;
      padding: 10px 20px;
    }
    .arrow {
      left: -40px !important;
      bottom: 5%;
      width: 40px !important;
    }
  }

  @media (max-width: 767.99px) {
    &__main-col {
      margin-left: 100px !important;
    }
    &__title {
      font-size: 25px;
      line-height: 30px !important;
    }

    &__text {
      font-size: 12px;
    }
    &__sub-text {
      font-size: 10px;
      top: 40px;
    }
    &__btn {
      font-size: 10px;
      padding: 8px 16px;
      letter-spacing: 0.2px;
    }
    .arrow {
      display: none;
    }
    .check-img {
      width: 15px !important;
    }
  }

  @media (max-width: 625.99px) {
    padding-top: 60px;
    padding-bottom: 30px;
    .check-img {
      width: 12px !important;
    }
    &__main-col {
      margin-left: 16% !important;
    }
    &__title {
      font-size: 20px;
      line-height: 25px !important;
    }

    &__text {
      font-size: 10px;
    }
    &__sub-text {
      font-size: 8px;
      top: 30px;
    }
    &__btn {
      font-size: 8px;
      padding: 7px 14px;
    }
  }

  @media (max-width: 575.99px) {
    padding-top: 150px;
    padding-bottom: 35px;
    .check-img {
      width: 10px !important;
    }
    &__main-col {
      margin-left: 12% !important;
    }
    &__title {
      font-size: 19px;
      line-height: 18px !important;
      margin-bottom: 5px !important;
    }
    &__secondaryTitle {
      font-size: 12px;
    }
    &__text {
      font-size: 13px;
    }
    &__sub-text {
      font-size: 10px;
      top: 38px;
    }
    &__btn {
      font-size: 15px;
      padding: 7px 14px;
      margin-top: 10px;
    }
  }

  @media (max-width: 374.99px) {
    padding-top: 140px;
    padding-bottom: 35px;
    &__main-col {
      margin-left: 11.5% !important;
    }
    &__title {
      font-size: 17px;
      line-height: 20px !important;
    }
    &__secondaryTitle {
      font-size: 11px;
    }
    &__text {
      font-size: 12px;
    }
    &__sub-text {
      font-size: 10px;
      top: 30px;
    }
    &__btn {
      font-size: 13px;
      padding: 6px 12px;
    }
  }
  @media (max-width: 349.99px) {
    &__text {
      font-size: 11px;
    }
  }
}
</style>
